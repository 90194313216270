<template>
  <div class="container">
    <b-form class="form" @submit.stop.prevent="submit">
      <div class="d-flex align-items-center">
        <a class="btn btn-alert mr-2" @click="$router.go(-1)">Back</a>

        <button type="submit" class="btn btn-success mr-2">Create</button>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <v-card dark class="h-100">
            <span class="card-icon">
              <i class="flaticon2-chat-1 text-dark"></i>
            </span>
            <v-card-title>Channel Builder <v-spacer> </v-spacer> </v-card-title>
            <div
              class="separator separator-solid separator-white opacity-20"
            ></div>
            <div class="card-body text-dark">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group row">
                    <label class="col-4 col-form-label text-white">Name</label>
                    <div class="col-8">
                      <b-form-input
                        v-model="$v.name.$model"
                        class="form-control"
                        :state="validateState('name')"
                        aria-describedby="input-1-live-feedback"
                        placeholder=""
                      ></b-form-input>

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        Name is required.
                      </b-form-invalid-feedback>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-5 col-form-label text-white"
                      >Status</label
                    >
                    <div class="col-7">
                      <b-form-select
                        v-model="status"
                        :options="statusOptions"
                      ></b-form-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <v-combobox
                      v-model="chips"
                      :items="tagNames"
                      chips
                      clearable
                      :search-input.sync="chipsearch"
                      label="Enter Tags followed by Enter key. (TAGS are a AND Logic)"
                      multiple
                      @change="chipsearch = ''"
                    >
                      <template
                        #selection="{
                          attrs,
                          item,
                          select,
                          selected,
                          parent,
                        }"
                      >
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="parent.selectItem(item)"
                        >
                          <strong>{{ item }}</strong
                          >&nbsp;
                        </v-chip>
                      </template>
                    </v-combobox>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6"></div>
                <div class="col-lg-6"></div>
              </div>
            </div>
          </v-card>
        </div>

        <div class="col-lg-6">
          <v-card class="mx-auto h-100">
            <img
              id="image_cover"
              style="width: 100%; max-height: 480px;"
              src="/media/product-placeholder.jpg"
            />

            <v-card-actions>
              <v-btn text>Main Image</v-btn>

              <b-form-file
                v-model="main_image"
                :state="Boolean(main_image)"
                placeholder="Upload Image"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @change="imageChange"
              ></b-form-file>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </div>
      </div>

      <div
        class="v-card--material pa-3 px-5 mt-3 mb-5 v-card v-sheet theme--light v-card--material--has-heading"
      >
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label class="col-3 col-form-label"> Description</label>

              <div class="col-9">
                <ckeditor
                  v-model="description"
                  :editor="editor"
                  :config="editorConfig"
                ></ckeditor>

                <pre class="mt-3 mb-0"></pre>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group row">
              <label class="col-3 col-form-label"> Short Description</label>

              <div class="col-9">
                <ckeditor
                  v-model="short_description"
                  :editor="editor"
                  :config="editorConfig"
                ></ckeditor>

                <pre class="mt-3 mb-0"></pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import GetCompanyTags from "@/graphql/queries/GetCompanyTags.gql";
import CreateFullChannel from "@/graphql/mutations/CreateFullChannel.gql";
import UploadFile from "@/graphql/mutations/UploadFile.gql";

export default {
  mixins: [validationMixin],
  data() {
    return {
      chipsearch: "",
      chips: [],
      name: "",
      status: "enabled",
      statusOptions: [
        {
          value: "enabled",
          text: "Enabled",
        },
        {
          value: "disabled",
          text: "Disabled",
        },
        {
          value: "pending",
          text: "Pending",
        },
      ],
      main_image_url: "",
      main_image: null,
      description: "",
      short_description: "",
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
          ],
        },
      },
    };
  },
  validations: {
    name: {
      required,
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    tagNames: function() {
      const tagHeader = [{ header: "Select an option or create one" }];
      const allTags = this.tags ? this.tags.map((t) => t.name) : [];
      return [].concat(tagHeader, allTags);
    },
  },
  apollo: {
    tags: {
      query: GetCompanyTags,
      variables() {
        return {
          companyId: this.$store.state.auth.user.company_relation,
        };
      },
    },
  },
  methods: {
    ...mapActions(["submitCategory"]),
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    imageChange(e) {
      let target = e.target || e.srcElement;
      let files = target.files;
      let fr = new FileReader();
      fr.onload = function() {
        document.getElementById("image_cover").src = fr.result;
      };
      fr.readAsDataURL(files[0]);
    },
    async submit(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      const newChannel = {
        name: this.name,
        status: this.status,
        description: this.description,
        short_description: this.short_description,
        supplier: this.$store.state.auth.user.company_relation,
      };

      try {
        const {
          data: {
            createFullChannel: { channel },
          },
        } = await this.$apollo.mutate({
          mutation: CreateFullChannel,
          variables: { newChannel, searchTags: this.chips },
        });

        const hasImageToUpload = this.main_image instanceof File;

        if (hasImageToUpload) {
          await this.$apollo.mutate({
            mutation: UploadFile,
            variables: {
              contentType: "channel",
              contentId: channel.id,
              field: "main_image",
              file: this.main_image,
            },
          });
        }
        // refreshes tags incase a new one was created
        this.$apollo.queries.tags.refetch();

        // this should probably be done in the same mutation on the backend

        await this.submitCategory({
          name: "Root",
          parent_id: 0,
          published: false,
          channel: channel.id,
        });

        Swal.fire({
          title: "",
          text: "The channel has been successfully created!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        this.$router.push("/pim/channels");
      } catch (error) {
        this.$bvToast.toast("An Error Occured!", {
          title: "Error",
          variant: "danger",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
        });
      }
    },
  },
};
</script>
